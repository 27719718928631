<template>
  <div>
    <b-card>
      <v-table
        ref="mailing-table"
        :path="tablePath"
        :fields="mailingItemTable"
        :filter="tableFilter"
        table-sm
        hide-create-button
      >
        <template #cell(serial)="data">
          <v-table-serial-column :data="data" />
        </template>
        <template #cell(amount)="{ item }">
          <a
            href="javascript: null"
            @click="onAmountClickHandler(item)"
          >{{ item.amount }}</a>
        </template>
      </v-table>
    </b-card>
  </div>
</template>

<script>
import VTable, { VTableSerialColumn } from '@/views/components/vtable'
import { mailingItemTable } from '@/views/mailing/mailingUse'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    VTable,
    VTableSerialColumn,
    BCard,
  },
  data() {
    return {
      mailingItemTable,
      tableFilter: {},
    }
  },
  computed: {
    tablePath() {
      return `mailing/${this.$route.params.id}?populate=driver,mailing`
    },
  },
  methods: {
    onAmountClickHandler(item) {
      this.$axios.post('/print/mailing-item', {
        driver_id: item.driver_id,
        date_start: item.mailing.date_start,
        date_end: item.mailing.date_end,
      }, { responseType: 'blob' })
    },
  }
}
</script>
