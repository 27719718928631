import moment from 'moment'
import { displayDriver } from '@/libs/filters'

const mailingStatusTitles = {
  new: 'Ожидет отправки',
  process: 'Идет отправка',
  done: 'Готово',
}

const mailingItemStatusTitles = {
  new: mailingStatusTitles.new,
  sent: 'Отправлено',
  error: 'Ошибк отправки',
}

export const mailingTable = [
  {
    key: 'serial',
    label: '#',
  },
  {
    key: 'date_start',
    label: 'Период',
    formatter(col, _, item) {
      return `${moment(item.date_start).format('DD.MM.YYYY')}/${moment(item.date_end).format('DD.MM.YYYY')}`
    },
  },
  {
    key: 'amount',
    label: 'Сумма',
  },
  {
    key: 'created_by',
    label: 'Создал',
  },
  {
    key: 'count_items',
    label: 'Прогресс',
  },
  {
    key: 'created_at',
    label: 'Дата',
    formatter(value) {
      return moment(value).format('DD.MM.YYYY')
    },
  },
  {
    key: 'status',
    label: 'Статус',
    formatter(value) {
      return Object.hasOwn(mailingStatusTitles, value) ? mailingStatusTitles[value] : value
    },
  },
]

export const mailingItemTable = [
  {
    key: 'serial',
    label: '#',
  },
  {
    key: 'driver_contract',
    label: 'Договор',
    formatter(value, _, item) {
      return `${item.driver.contract_serial} ${item.driver.contract_number}`
    },
  },
  {
    key: 'driver',
    label: 'ФИО',
    formatter(value) {
      return displayDriver(value)
    },
  },
  {
    key: 'amount',
    label: 'Сумма',
  },
  {
    key: 'driver_email',
    label: 'Email',
    formatter(value, _, item) {
      return item.driver.email
    },
  },
  {
    key: 'date_sent',
    label: 'Дата отправки',
    formatter(value) {
      return value ? moment(value).format('DD.MM.YYYY HH:mm') : '-'
    },
  },
  {
    key: 'status',
    label: 'Статус',
    formatter(value) {
      return Object.hasOwn(mailingItemStatusTitles, value) ? mailingItemStatusTitles[value] : value
    },
  },
]
